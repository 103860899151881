<template>
  <div v-if="user" class="subscription-page">
    <b-button
      v-if="!isImpersonating"
      variant="primary"
      class="d-block logout-button"
      @click.prevent="logout"
    >
      <span>LogOut</span>
    </b-button>
    <div v-if="isImpersonating && !user.subscribed" class="text-center mb-4">
      <button
        class="btn btn-outline-primary btn-sm"
        :disabled="loadingStopImpersonate"
        @click="stopImpersonate"
      >
        <b-spinner v-if="loadingStopImpersonate" small />
        <i class="uil uil-sign-in-alt"></i> Main account
      </button>
    </div>
    <div v-if="currentStep === 1" class="cart-step">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card">
            <div class="card-body">
              <h3 class="h3 text-center mb-4">
                The billing has failed for <span>{{ user.full_name }}</span
                >.<br />
                Please update your card information below to get access.
              </h3>
              <div class="row mb-4 mt-5">
                <div class="col-md-8 offset-md-2">
                  <AddCreditCard ref="cardForm" />
                  <div class="text-center mt-4">
                    <button
                      class="btn btn-primary w-100"
                      :disabled="loadingSubscription"
                      @click="validateCard"
                    >
                      <b-spinner v-if="loadingSubscription" small />
                      <span v-else>ADD PAYMENT METHOD</span>
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="user.default_payment_method" class="text-center pt-4">
                <a
                  href="javascript:void(0)"
                  class="h5 text-primary"
                  @click="getPaymentMethods"
                >
                  Use existing Payment Method
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentStep === 2" class="payment-methods">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card">
            <div class="card-body">
              <div v-if="loadingPaymentMethod" class="text-center">
                <b-spinner />
              </div>
              <div v-if="!loadingPaymentMethod">
                <div
                  v-for="payment in paymentMethods"
                  :key="payment.id"
                  class="payment-method"
                >
                  <div class="h5 text-primary text-capitalize">{{
                    payment.brand
                  }}</div>
                  <div class="card-number"
                    >{{ payment.brand }} ****{{ payment.last_four }}</div
                  >
                  <div class="card-expiration"
                    >Expires: {{ payment.exp_month }} /
                    {{ payment.exp_year }}</div
                  >
                  <div class="actions">
                    <button
                      class="btn btn-sm px-sm-4 btn-primary"
                      :disabled="loadingSetDefault"
                      @click="setDefaultPaymentMethod(payment.id)"
                    >
                      <b-spinner v-if="loadingSetDefault" small />
                      <span v-else>Select</span>
                    </button>
                  </div>
                  <hr />
                </div>
              </div>
              <div v-if="!loadingPaymentMethod" class="text-center pt-4">
                <a
                  href="javascript:void(0)"
                  class="h5 text-primary"
                  @click="addPaymentMethod"
                >
                  Add payment method
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentStep === 3">
      <div v-if="!loadingPlans" class="row">
        <div v-for="plan in plans" :key="plan.id" class="col-lg-4">
          <div class="card card-pricing">
            <div class="card-body p-4">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0 mb-2 font-size-16">{{ plan.name }}</h5>
                  <h2 class="mt-0 mb-2"
                    >${{ plan.amount }}
                    <span class="font-size-14">/ Month</span></h2
                  >
                </div>
                <div class="align-self-center">
                  <feather type="user" />
                </div>
              </div>

              <div class="mt-5 text-center">
                <button
                  class="btn px-sm-4 btn-primary"
                  :disabled="
                    loadingSubscription !== null || plan.id === user.stripe_plan
                  "
                  @click="subscription(plan.id)"
                >
                  <b-spinner v-if="loadingSubscription === plan.id" small />
                  <span v-else>
                    <span v-if="plan.id === user.stripe_plan">Active</span>
                    <span v-else>
                      <i class="uil uil-arrow-right mr-1"></i>Buy Now for ${{
                        plan.amount
                      }}
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center mt-5">
        <b-spinner large></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import AddCreditCard from '../Common/AddCreditCard'

export default {
  components: { AddCreditCard },

  data() {
    return {
      loadingPlans: false,
      loadingSubscription: null,
      showAddCard: false,
      loadingCreateCard: false,
      selectedPlan: null,
      currentStep: 1,
      loadingPaymentMethod: false,
      loadingSetDefault: false,
      loadingStopImpersonate: false,
    }
  },

  computed: {
    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },

    plans() {
      let plans = this.$store.getters['subscription/allPlans'] || []

      return plans.filter(
        (plan) => plan.visible || plan.id === this.user.stripe_plan
      )
    },

    user() {
      return this.$store.getters['auth/user']
    },

    paymentMethods() {
      return this.$store.getters['paymentMethod/all']
    },
  },

  mounted() {
    if (this.user.default_payment_method && this.user.subscribed) {
      this.getPlans()
    }
  },

  methods: {
    logout() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(() => {})
    },

    onSuccessHandler() {
      if (this.user.agency) {
        this.$router.push({ name: 'agency.businesses.index' })
      } else {
        this.getPlans()
      }
    },

    onFailedHandler() {},

    getPlans() {
      this.currentStep = 3
      if (this.$store.getters['subscription/allPlans']) return

      this.loadingPlans = true
      this.$store
        .dispatch('subscription/getAllPlans')
        .then(() => {
          this.loadingPlans = false
        })
        .catch(() => {
          this.loadingPlans = false
        })
    },

    subscription(plan) {
      this.loadingSubscription = plan

      this.$store
        .dispatch('subscription/subscribe', plan)
        .then(() => {
          this.loadingSubscription = null
          this.$router.push({ name: 'agency.businesses.index' })
        })
        .catch(() => {
          this.loadingSubscription = null
          this.currentStep = 1
        })
    },

    addPaymentMethod() {
      this.currentStep = 1
    },

    createPaymentMethod(paymentMethodId) {
      this.$store
        .dispatch('paymentMethod/create', paymentMethodId)
        .then(() => {
          this.$store
            .dispatch('subscription/resume')
            .then(() => {
              this.loadingSubscription = null
              this.onSuccessHandler()
            })
            .catch(() => {
              this.loadingSubscription = null
              this.getPlans()
            })
        })
        .catch(() => {
          this.loadingSubscription = null
        })
    },

    setDefaultPaymentMethod(paymentMethodId) {
      this.loadingSetDefault = true
      this.$store
        .dispatch('paymentMethod/setDefault', paymentMethodId)
        .then((res) => {
          this.$store
            .dispatch('subscription/resume')
            .then(() => {
              this.loadingSetDefault = false
              this.onSuccessHandler()
            })
            .catch(() => {
              this.loadingSetDefault = false
              this.getPlans()
            })
        })
        .catch((errors) => {
          this.loadingSetDefault = false
        })
    },

    getPaymentMethods() {
      this.currentStep = 2
      this.loadingPaymentMethod = true
      this.$store
        .dispatch('paymentMethod/getAll')
        .then((res) => {
          this.loadingPaymentMethod = false
        })
        .catch(() => {
          this.loadingPaymentMethod = false
        })
    },

    async validateCard() {
      this.loadingSubscription = true

      await this.$refs.cardForm
        .validate()
        .then((paymentMethodId) => {
          this.createPaymentMethod(paymentMethodId)
        })
        .catch(() => {
          this.loadingSubscription = null
        })
    },

    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
        .dispatch('auth/stopImpersonate')
        .then(() => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/admin')
              this.loadingStopImpersonate = false
            })
            .catch(() => {
              this.loadingStopImpersonate = false
            })
        })
        .catch(() => {
          this.loadingStopImpersonate = false
        })
    },
  },
}
</script>

<style lang="scss">
.subscription-page {
  .payment-methods {
    .payment-method {
      padding: 10px 0;
      .card-brand {
        font-size: 16px;
        font-weight: 500;
        color: #2c2e2f;
        text-transform: uppercase;
      }
      .card-bumber,
      .card-expiration {
        font-weight: 500;
        color: #666;
      }
    }
  }
  .payment-methods .payment-method .actions {
    margin-top: 10px;
  }
  .payment-methods .payment-method .actions a[disabled] {
    opacity: 0.6;
  }
  .logout-button {
    max-width: 200px;
    position: absolute;
    top: 50px;
    right: 50px;
  }
}
</style>
